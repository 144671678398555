.add-candidate-container {
  background-color: f5f5dc;
  color: black;
}


.add-candidate-container .panel logo {
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's on top of other content */
}

.add-candidate-container .panel {
  padding: 20px;
  border: 1px solid var(--theme-color);
  margin-bottom: 20px;
  //background-color: gray; /* Set background color of panel */
  background-color: #f5f5dc; ; /* Set background color of panel */
  color: black; /* Set text color of panel */
}

.add-candidate-container .panel h2 {
  margin-top: 0;
  color: black;
}
.add-candidate-container .panel h3 {
  margin-top: 0;
  color: black;
}

.add-candidate-countainer .panel h4 {
  padding-left: 20px; /* Adjust as needed */
  color: black;
  font-weight: normal; /* Set font weight to normal (non-bold) */
  margin-top: 20px;
}

.add-candidate-container .panel h7 {
  padding-top: 200px;
  margin-top: 200px;
  color: black;
}

.add-candidate-countainer .label {
  padding-left: 20px; /* Adjust as needed */
  //color: var(--theme-color);
  color: wheat;
  font-weight: normal; /* Set font weight to normal (non-bold) */
  margin-top: 50px;
}
.add-candidate-container table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 40px;
  color: black;
}

.add-candidate-container th,
.add-candidate-container td {
  border: 1px solid black;
  padding: 10px;
  color: black;
}

.cv-link {
  color: #007bff;
  text-decoration: none;
  
  &:hover {
      text-decoration: underline;
      cursor: pointer;
  }
}

.add-candidate-container {
  padding: 20px;
  
  .panel {
      background: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      h1 {
          margin-bottom: 20px;
          color: #333;
      }
  }

  .filters {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      flex-wrap: wrap;

      input {
          flex: 1;
          min-width: 200px;
          padding: 8px 12px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &:focus {
              outline: none;
              border-color: #007bff;
              box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
          }

          &::placeholder {
              color: #999;
          }
      }
  }

  .table-container {
      overflow-x: auto;
      
      table {
          width: 100%;
          border-collapse: collapse;
          
          th, td {
              padding: 12px;
              text-align: left;
              border-bottom: 1px solid #ddd;
          }
          
          th {
              background-color: #f8f9fa;
              font-weight: 600;
              color: #333;
          }
          
          tr:hover {
              background-color: #f5f5f5;
          }

          td {
              color: #666;
          }
      }
  }

  .cv-link {
      background: none;
      border: none;
      color: #007bff;
      cursor: pointer;
      padding: 0;
      font: inherit;
      text-decoration: underline;
      
      &:hover {
          color: #0056b3;
      }
  }
}

.accordion {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;

  .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      background-color: #f8f9fa;
      cursor: pointer;

      h2 {
          margin: 0;
          font-size: 1.2rem;
      }

      .arrow {
          transition: transform 0.3s ease;
          
          &.open {
              transform: rotate(180deg);
          }
      }
  }
}

.add-candidate-form {
  padding: 20px;

  .form-group {
      margin-bottom: 15px;

      input, textarea {
          width: 100%;
          padding: 8px 12px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &:focus {
              outline: none;
              border-color: #007bff;
              box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
          }
      }

      textarea {
          resize: vertical;
          min-height: 100px;
      }
  }

  .submit-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
          background-color: #0056b3;
      }
  }
}