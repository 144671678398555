.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--theme-color) /* Use your preferred background color */
}

.login-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.login-container form {
  width: 300px;
}

.login-container form div {
  margin-bottom: 20px;
}

.login-container form label {
  font-size: 20px;
  font-weight: bold;
}

.login-container form input[type="text"],
.login-container form input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

.login-container form button {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}

.error-message {
  color: red;
  font-size: 18px;
  margin-top: 10px;
}

