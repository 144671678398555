.team-page {
  section {
    padding: 60px 0;
    position: relative;
    z-index: 1;

    .title {
      font-size: 72px;
    }

    @media screen and (max-width: 1200px) {
      padding: 60px 0;
      .title {
        font-size: 66px;
      }
    }
    @media screen and (max-width: 992px) {
      padding: 60px 0;
      .title {
        font-size: 60px;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 60px 0;
      .title {
        font-size: 54px;
      }
    }
    @media screen and (max-width: 576px) {
      padding: 60px 0;
      .title {
        font-size: 48px;
      }
    }
  }


  //////////////////////////////////////////////////////////////////
  ///////////////////////// LAUNCH SECTION /////////////////////////
  //////////////////////////////////////////////////////////////////

  .launch-section {
    background-color: var(--theme-color);


    img.infinite {
      position: absolute;
      width: 40%;
      max-width: 520px;
      left: 10%;
      top: -40px;
      z-index: -1;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    img.rect {
      position: absolute;
      width: 40%;
      height: 200px;
      max-width: 460px;
      min-width: 400px;
      left: 0;
      bottom: 80px;
      z-index: -1;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    .container {
      display: flex;
      align-items: center;
      gap: 54px;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      .left {
        width: 0;
        flex-grow: 1;

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        img {
          width: 80%;
          max-width: 460px;
        }
      }

      .right {
        width: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        .description {
          font-size: 24px;
          padding-top: 28px;

          @media screen and (max-width: 992px) {
            font-size: 18px;
          }
        }

        pre {
          font-size: 24px;
          font-weight: bold;
          background-color: #85ffda;

          overflow-x: auto;
          white-space: pre-wrap;
          word-wrap: break-word;

          margin: 36px 0 42px 0;
          padding: 2px 6px;
        }

        a {
          display: block;
          padding: 14px 40px;
          background-color: black;

          color: white;
          font-weight: bold;
          font-size: 22px;

          border-radius: 70px;

          @media screen and (max-width: 576px) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////// WITH RAYZE SECTION ///////////////////////
  //////////////////////////////////////////////////////////////////

  .with-rayze-section {
    background-color: black;
    color: white;


    .container {
      .top {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        font-size: 25px;
        flex-direction: column;
        align-items: left;

        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
  

          img {
            width: 200px;
            margin-left: 40px;

            @media screen and (max-width: 992px) {
              width: 180px;
            }
            @media screen and (max-width: 768px) {
              width: 160px;
            }
          }

          pre {
            margin: 20px 0;
          }
        }

        .right {
          text-align: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            width: 96%;
            max-width: 570px;
            font-size: 24px;

            @media screen and (max-width: 992px) {
              font-size: 20px;
            }
          }

          .title {
            width: 100%;
            max-width: 650px;
            color: var(--theme-color);
          }
        }

        @media screen and (max-width: 992px) {
          flex-direction: column;
          .left {
            order: 2;
            padding-top: 20px;
            align-items: flex-start;
          }
          .right {
            order: 1;
            text-align: left;
            align-items: flex-start;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        gap: 32px;

        > div {
          width: 250px;

          h4 {
            color: var(--theme-color);
            font-size: 36px;
            margin: 24px 0;
          }
          p {
            font-size: 20px;
            margin: 0;
          }
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          margin-top: 30px;
        }
      }
    }
  }


  //////////////////////////////////////////////////////////////////
  ////////////////////// LAUNCH STEPS SECTION //////////////////////
  //////////////////////////////////////////////////////////////////

  .launch-steps-section {
    background-color: white;

    .arc {
      height: 70vw;
      max-height: 110%;
      position: absolute;
      left: 0;
      z-index: -1;
      bottom: -10%;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 66px;
      align-items: flex-end;

      .title {
        width: 100%;
        max-width: 630px;
        text-align: right;
      }

      .steps {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 54px;

        @media screen and (max-width: 992px) {
          gap: 36px;
        }

        .step {
          max-width: 230px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          h4 {
            font-size: 36px;
            color: var(--theme-color);
            margin: 0;
          }

          p {
            font-size: 24px;
            margin: 0 0 auto 0;

            @media screen and (max-width: 992px) {
              font-size: 20px;
            }
          }

          img {
            width: 72px;
            margin-top: 12px;
          }
        }

        .line {
          width: 1px;
          background-color: black;
          margin-top: 18px;
          border: none;
        }
      }

      @media screen and (max-width: 768px) {
        .title {
          width: 100%;
          max-width: 2000px;
          text-align: left;
        }

        .steps {
          flex-direction: column;
          align-items: center;

          .line {
            width: 200px;
            height: 1px;
          }

          .step {
            max-width: 80%;
            align-items: center;
            p { text-align: center; }
          }
        }
      }

    }
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////// INSENTIVE SECTION ////////////////////////
  //////////////////////////////////////////////////////////////////

  .insentive-section {
    background-color: black;
    color: white;

    .container {
      display: flex;
      gap: 66px;
      flex-direction: column;

      .title {
        width: 100%;
        max-width: 700px;
      }

      .steps {
        display: flex;
        justify-content: space-between;
        gap: 54px;

        @media screen and (max-width: 992px) {
          gap: 36px;
          flex-direction: column;
        }

        .step {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h4 {
            font-size: 24px;
            color: var(--theme-color);
            margin: 0;
          }

          p {
            font-size: 22px;
            margin: 0 0 auto 0;

            @media screen and (max-width: 992px) {
              font-size: 18px;
            }
          }

          img {
            width: 72px;
            margin-top: 12px;
          }
        }

        .line {
          width: 1px;
          background-color: white;
          margin-top: 18px;
          border: none;

          @media screen and (max-width: 992px) {
            width: 200px;
            height: 1px;
            margin: 0;
          }
        }
      }

      a {
        padding: 14px 50px;
        border-radius: 100px;
        background-color: var(--theme-color);
        color: black;
        margin: auto;
        text-align: center;
        font-weight: bold;

        &:hover {
          opacity: 0.8;
        }

        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }


  //////////////////////////////////////////////////////////////////
  //////////////////////// FEATURES SECTION ////////////////////////
  //////////////////////////////////////////////////////////////////

  .features-section {
    background-color: #D0FF00;
    .container {
      display: flex;
      gap: 66px;
      flex-direction: column;

      .title {
        width: 100%;
        text-align: right;
        @media screen and (max-width: 992px) {
          text-align: left;
        }
      }

      .steps {
        display: flex;
        justify-content: space-between;
        gap: 48px;

        @media screen and (max-width: 1200px) {
          gap: 32px;
        }

        @media screen and (max-width: 992px) {
          flex-direction: column;
        }

        .step {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h4 {
            font-size: 24px;
            margin: 0;
          }

          p {
            font-size: 20px;
            margin: 0 0 auto 0;

            @media screen and (max-width: 992px) {
              font-size: 18px;
            }
          }

          img {
            width: 72px;
            margin-top: 12px;
          }
        }

        .line {
          width: 1px;
          background-color: black;
          margin-top: 18px;
          border: none;

          @media screen and (max-width: 992px) {
            width: 200px;
            height: 1px;
            margin: 0;
          }
        }
      }

      a {
        padding: 14px 50px;
        border-radius: 100px;
        background-color: white;
        color: black;
        margin: auto;
        text-align: center;
        font-weight: bold;

        &:hover {
          opacity: 0.9;
        }

        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////// COMMUNITY SECTION ///////////////////////
  //////////////////////////////////////////////////////////////////

  .community-section {
    background-color: white;

    .circle {
      position: absolute;
      right: 0;
      top: 400px;
      z-index: -1;

      @media screen and (max-width: 768px) {
        height: 150px;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 80px;
      align-items: center;

      .title {
        text-align: center;
      }

      a {
        text-decoration: none;
        padding: 14px 50px;
        border-radius: 100px;
        text-align: center;
        background-color: var(--theme-color);
        color: black;

        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }
}
