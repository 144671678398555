table.collection-table {
  min-width: 100%;
  width: 960px;
  border-collapse: collapse;
  border: 1px solid #828282;

  th{
    border: 1px solid #828282;
    background-color: var(--theme-color);
    padding: 24px 12px;
  }

  td {
    text-align: center;

    border: 1px solid #828282;
    padding: 16px 12px;

    button {
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
      background-color: transparent;
    }

    .collection-name {
      display: flex;
      font-weight: bold;
      gap: 12px;
      align-items: center;
      justify-content: center;

      .collection-image {
        width: 36px;
        height: 36px;
        background-color: gray;
        border-radius: 50%;
      }
    }
  }
}
